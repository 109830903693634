import React from 'react'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import BrandMuiTheme from 'muitheme'
import { ThemeProvider } from 'styled-components'
import PropTypes from 'prop-types'
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const muiTheme = createMuiTheme(BrandMuiTheme)
const Wrapper = ({ element }) => {
  return <MuiThemeProvider theme={muiTheme}>
    <ThemeProvider theme={muiTheme}>
      {element}
    </ThemeProvider>
  </MuiThemeProvider>
}
Wrapper.propTypes = {
  element: PropTypes.element
}

export default Wrapper
