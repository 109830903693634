/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.onRouteUpdate = ({ location }) => {
  const sendPageView = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'virtualPageView',
      userType: 'anonymous',
      pageApplication: 'front_of_site',
      page: {
        title: window.document.title,
        url: location.pathname,
        href: location.href
      }
    })
  }
  if (`requestAnimationFrame` in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(sendPageView)
    })
  } else {
    // simulate 2 rAF calls
    setTimeout(sendPageView, 32)
  }
}
